import images from "./images";

const services = [
  {
    image: `${images.service_1}`,
    title: "בניה ועיצוב 🚀",
    paragraph:
      "בנייה ועיצוב אישי של אתרי אינטרנט באמצעות קוד, המותאמים לכל סוגי העסקים המעוניינים למנף את העסק שלהם באינטרנט ולהגיע ליותר ויותר  לקוחות",
      popup: "בנייה ועיצוב אישי של אתרי אינטרנט היא המומחיות שלנו. אנחנו מתמקדים במתן פתרונות מותאמים אישית לכל סוגי העסקים שרוצים למנף את הנוכחות שלהם באינטרנט ולהגיע ליותר לקוחות. עם שליטה מלאה על כל רכיב בדף בזכות שימוש בקוד מתקדם, אנחנו יוצרים אתרים ייחודיים, מהירים ורספונסיביים, שמתאימים בדיוק לצרכי העסק שלכם – בין אם אתם עסק קטן או מותג מוביל"
  },
  {
    image: `${images.service_2}`,
    title: "טכנולוגיה 🌐",
    paragraph:
      "אנו בונים אתרים בקוד באמצעות הטכנולוגיות ושפות התכנות המתקדמות ביותר, המאפשרות לבנות אתרים מהירים, מותאמים לנייד וידידותיים לשימוש",
      popup: "הטכנולוגיה שלנו היא מה שהופכת את האתרים שאנחנו בונים למובילים בתחומם. אנו משתמשים בטכנולוגיות ובשפות התכנות המתקדמות ביותר כדי להבטיח שאתרי האינטרנט יהיו לא רק יפים אלא גם מהירים, מותאמים באופן מושלם למובייל, וידידותיים למשתמש. הגישה שלנו מאפשרת לכם ליהנות מאתרים שמתפקדים בצורה חלקה, נטענים במהירות, ומספקים חוויית משתמש איכותית בכל מכשיר"

  },
  {
    image: `${images.service_3}`,
    title: "קידום בגוגל 📊",
    paragraph:
      "לאחר הקמת האתר, תוכלו לקבל אצלנו שירותי קידום ולקדם את האתר באמצעות קידום אורגני בגוגל, וכך לקבל חשיפה גדולה יותר במנועי חיפוש",
      popup: "לאחר בניית האתר, אנחנו מציעים גם שירותי קידום אורגני בגוגל כדי להבטיח שהאתר שלכם יבלוט וימשוך את תשומת הלב הנכונה. באמצעות קידום אורגני, האתר שלכם יתקדם בתוצאות החיפוש, מה שמוביל לחשיפה רחבה יותר ולגידול משמעותי בתנועת הגולשים. כך תבטיחו שהלקוחות הפוטנציאליים ימצאו אתכם בקלות, בדיוק כשיחפשו את מה שאתם מציעים. הקידום האורגני שלנו מותאם אישית לאתר ולמטרות העסק שלכם, ומסייע לכם לבסס נוכחות חזקה ויציבה במנועי החיפוש לאורך זמן"

  },
  // {
  //   image: `${images.service_3}`,
  //   title: "מובייל",
  //   paragraph:
  //     "אנו מתאימים את האתרים שאנחנו בונים לשימוש וגלישה נוחה בכל סוגי המכשירים הניידים, שכוללים פונקציונאליות של כפתור וואטצאפ שמוביל את הגולשים ישירות אליכם.",
  // },
  // {
  //   image: `${images.service_3}`,
  //   title: "שינוי תוכן באתר",
  //   paragraph:
  //     "אנו בונים אתרים בקוד באמצעות הטכנולוגיות ושפות התכנות המתקדמות ביותר, המאפשרות לבנות אתרים מהירים, נוחים וידידותיים לשימוש.",
  // },
  // {
  //   image: `${images.service_3}`,
  //   title: "מחירים נוחים",
  //   paragraph:
  //     "לאחר הקמת האתר, תוכלו לקבל אצלנו שירותי קידום ולקדם את האתר באמצעות קידום אורגני בגוגל, וכך לקבל חשיפה גדולה יותר במנועי חיפוש.",
  // },
];

const works = [
  {
    image: `${images.work_1}`,
    name: "gymfitness",
    link: "https://www.gymfitness.online/",
  },
  {
    image: `${images.work_2}`,
    name: "Tatto shot",
    link: "https://inked.onrender.com/",
  },
  {
    image: `${images.work_3}`,
    name: "Photographer",
    link: "https://photograph-ijdi.onrender.com/",
  },
  {
    image: `${images.work_4}`,
    name: "Camera Pro",
    link: "https://www.camera-pro.online/",
  },
];

export { services, works };
